window.addEventListener('DOMContentLoaded', () => {

  // Отправка данных на сервер
function send(event, php){
  console.log("Отправка запроса");
  event.preventDefault ? event.preventDefault() : event.returnValue = false;
  var req = new XMLHttpRequest();
  req.open('POST', php, true);
  req.onload = function() {
    if (req.status >= 200 && req.status < 400) {
    json = JSON.parse(this.response); // Ебанный internet explorer 11
        console.log(json);
          
        // ЗДЕСЬ УКАЗЫВАЕМ ДЕЙСТВИЯ В СЛУЧАЕ УСПЕХА ИЛИ НЕУДАЧИ
        if (json.result == "success") {
          // Если сообщение отправлено
          alert("Сообщение отправлено");
        } else {
          // Если произошла ошибка
          alert("Ошибка. Сообщение не отправлено");
        }
      // Если не удалось связаться с php файлом
      } else {alert("Ошибка сервера. Номер: "+req.status);}}; 
  
  // Если не удалось отправить запрос. Стоит блок на хостинге
  req.onerror = function() {alert("Ошибка отправки запроса");};
  req.send(new FormData(event.target));
  }

  // Burger Menu mobile start
  
  const burgerMenuIcon = document.querySelector('[data-burger="menu"]'),
        burgerMenuNav = document.querySelector('.navbar'),
        burgerMenuClose = document.querySelector('.navbar');
  
        burgerMenuIcon.addEventListener('click', () => {
          burgerMenuNav.classList.add('active');
          document.body.style.overflow = 'hidden';
        });
  
        burgerMenuClose.addEventListener('click', () => {
          burgerMenuNav.classList.remove('active');
          document.body.style.overflow = '';
        });
  
  // Burger Menu mobile END
  
  // multiple choice questionnaire and a form at the end
  
  const quiz = document.querySelector('[data-action="quiz"]');
  const question = document.querySelectorAll('.quiz__question');
  const btnNext = document.querySelectorAll('.btn-next');
  const quizNumber = document.querySelector('#quiz-number');
  const quizLabel = document.querySelector('.quiz__label');
  const inpuChecked = document.querySelectorAll('.quiz__check');
  
  function hideQuizQuestion() {
    question.forEach((quiz) => {
      quiz.classList.add('hide');
      quiz.classList.remove('active');
    })
  }
  
  function activeQuizQuestion(i = 0) {
    question[i].classList.add('active');
    question[i].classList.remove('hide');
  }
  
  hideQuizQuestion();
  activeQuizQuestion();


let num = parseFloat(100 / 7).toFixed(2);

function nextQuiz(event) {
  if (event.target.classList.contains('btn-next')) {
    const target = event.target;

    hideQuizQuestion();
    activeQuizQuestion(target.dataset.next);

    let index = +target.dataset.next + 1;

    quizNumber.innerText = index;

    let indecatorNum = parseFloat(num * index).toFixed();

    quizLabel.style.width = indecatorNum + "%";
    
  }
}

function backNext(event) {
  if (event.target.classList.contains('quiz__btn-back')) {
    const target = event.target;
    hideQuizQuestion();
    activeQuizQuestion(target.dataset.back);

    let numIndex = +target.dataset.back + 1;

    quizNumber.innerText = numIndex;

    let indecatorNumTo = parseFloat(num * numIndex).toFixed();

    quizLabel.style.width = indecatorNumTo + "%";
  }
}

  quiz.addEventListener('click', (event) => {
    nextQuiz(event);
    backNext(event);
  });



// slider for the number of windows start 

  const rangeValueView = document.querySelector('#rangeValue');
  const rangeValue = document.querySelector('[data-range="value"]');

  rangeValue.addEventListener('input', function() {
    rangeValueView.innerText = this.value;
  });


// slider for the number of windows end 

// Smooth scroll to anchor START

const smoothLinks = document.querySelectorAll('a[href^="#"]');
for (let smoothLink of smoothLinks) {
    smoothLink.addEventListener('click', function (e) {
        e.preventDefault();
        const id = smoothLink.getAttribute('href');

        document.querySelector(id).scrollIntoView({
            behavior: 'smooth',
            block: 'start'
        });
    });
};
  
  // Smooth scroll to anchor END
  
  // multiple choice questionnaire and a form at the END
  
  
  // Leave a request to measure the beginning of the code
  
  const frozeButton = document.querySelectorAll('[data-modal]'),
        frozeModal = document.querySelector('[data-froze="modal"]'),
        closeModalBtn = document.querySelector('[data-close]');
  
        frozeButton.forEach(buttonModal => {
          buttonModal.addEventListener('click', () => {
            frozeModal.classList.add('show');
            frozeModal.classList.remove('hide');
            // Либо вариант с toggle - но тогда назначить класс в верстке
            document.body.style.overflow = 'hidden';
          });
        })
  
        function closeModal() {
          frozeModal.classList.add('hide');
          frozeModal.classList.remove('show');
          // Либо вариант с toggle - но тогда назначить класс в верстке
          document.body.style.overflow = '';
      }
  
      closeModalBtn.addEventListener('click', closeModal);
    
      frozeModal.addEventListener('click', (e) => {
          if (e.target === frozeModal) {
              closeModal();
          }
      });
  
      document.addEventListener('keydown', (e) => {
        if (e.code === "Escape" && frozeModal.classList.contains('show')) { 
            closeModal();
        }
      });
  
  // Leave a request for freezing the end of the code
  
  
  // tabs with content glazing windows code start
  
  const tabs = document.querySelectorAll('.glazing__item'),
        tabsContent = document.querySelectorAll('[data-tab="content"]'),
        tabsParent = document.querySelector('#tab-parent');
  
  function hideTabContent() {
        tabsContent.forEach(item => {
            item.classList.add('hide');
            item.classList.remove('flex', 'fade');
        });
  
        tabs.forEach(item => {
            item.classList.remove('glazing__item-active');
        });
  }
  
  function showTabContent(i = 0) {
    tabsContent[i].classList.add('flex', 'fade');
    tabsContent[i].classList.remove('hide');
    tabs[i].classList.add('glazing__item-active');
  }
  
  hideTabContent();
  showTabContent();
  
  tabsParent.addEventListener('click', function(event) {
    const target = event.target;
    if(target && target.classList.contains('glazing__item')) {
            tabs.forEach((item, i) => {
                if (target == item) {
                    hideTabContent();
                    showTabContent(i);
                }
            });
    }
  });
  
  // tabs with content glazing windows code END ----------------
  
  // Frequently Asked Questions code start
  
  const questionsParent = document.querySelectorAll('.questions__items-wrap');
  
        questionsParent.forEach((item) => {
          const questionsOpen = item.querySelector('.questions__item-open');
          const questionsClose = item.querySelector('.questions__item-close');
          const questionsContent = item.querySelector('.questions__content');
  
          item.addEventListener('click', () => {
            questionsContent.classList.toggle('show');
  
            if (questionsContent.classList.contains === 'show') {
              questionsOpen.classList.add('show');
              questionsClose.classList.add('hide');
            } else {
              questionsOpen.classList.add('hide');
              questionsClose.classList.add('show');
            }
          });
        })


  
  // Frequently Asked Questions code END ----------------
  
  // Click on a photo and change the image to the main one start

  const imageBasicToOne = document.querySelectorAll('[data-slide-to]');
  const imageBasicToTho = document.querySelectorAll('[data-slide-tho]');
  const imageBasicToThree = document.querySelectorAll('[data-slide-three]');
  const imageBasicToFour = document.querySelectorAll('[data-slide-four]');
  const imageBasicToFive = document.querySelectorAll('[data-slide-five]');
  const imageBasicToSix = document.querySelectorAll('[data-slide-six]');

  function imageBasic(tranm, images) {
    tranm.forEach((img) => {
      img.addEventListener('click', (event) => {
        let imageStatic = document.querySelector(images).src = event.target.src;
      });
    });
  }

  imageBasic(imageBasicToOne, '#target-one');
  imageBasic(imageBasicToTho, '#target-tho');
  imageBasic(imageBasicToThree, '#target-three');
  imageBasic(imageBasicToFour, '#target-four');
  imageBasic(imageBasicToFive, '#target-five');
  imageBasic(imageBasicToSix, '#target-six');

    // Click on a photo and change the image to the main one END
  
  
    window.onload = function() {
      const autoPlay = document.querySelector('[data-auto="play"]');
      const YoutubePlay = document.querySelector('[data-youtube="lazy"]');

      function yuotube() {
        YoutubePlay.innerHTML = `
        <iframe width="100%" height="410" src="https://www.youtube.com/embed/E_a19P4rOUA?autoplay=1"
        title="Видеоотзыв"  frameborder="0" name="youtube embed" allow="autoplay; encrypted-media">
        </iframe>
        `;
      }

      setTimeout(() => {
        autoPlay.play();
      }, 5000);

      YoutubePlay.addEventListener('click', () => {
        yuotube();
      });
    }


    const data = document.querySelector('#date');

    let today = new Date();
    data.innerText = today.getFullYear();
    
  });